import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams, Response, HttpModule, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
 


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  base_url: string;
  constructor(
    private http: HttpClient,
  ) {
    this.base_url = environment.appUrl + "/orders";
  }

  getAllOrders(): Observable<any> {
    return this.http.get(this.base_url);
  }

  getAllProductsInOrder(orderId: Number): Observable<any> {
    return this.http.get(this.base_url + '/id/' + orderId + '/' + "products")
  }

  getOrderCountByStatus(from, to): Observable<any> {
    if ((!window.sessionStorage.getItem("selectedBranchesCodes")) || (window.sessionStorage.getItem("selectedBranchesCodes") && window.sessionStorage.getItem("totalUserBranches") == JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length)) {
      return this.http.get(this.base_url + '/status/count/branch?fromDate=' + from + '&toDate=' + to)
    }
    else {
      return this.http.get(this.base_url + '/status/count/branch?fromDate=' + from + '&toDate=' + to + '&branchCodes=' + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
    }
  }

  prepareOrderForDelivery(order): Observable<any> {
    return this.http.post(this.base_url + '/splitorder', order)
  }

  orderUpdate(order): Observable<any> {
    return this.http.put(this.base_url, order)
  }

  updateDispatchDate(orderId,dispatchDate): Observable<any> {
    return this.http.patch(this.base_url + "/"+ orderId ,dispatchDate)
  }

  getChartData(): Observable<any> {
    return this.http.get(this.base_url + '/dashboard/trendanalysis');
  }

  getChartFleet(): Observable<any> {
    return this.http.get(this.base_url + '/dashboard/statbyfleet');
  }

  getChartZone(): Observable<any> {
    return this.http.get(this.base_url + '/dashboard/statbyzone');
  }

  getDashbordData(): Observable<any> {
    return this.http.get(this.base_url + '/dashboard/allupstats');
  }

  getBranchDashboardData(from,to): Observable<any> {
    return this.http.get(this.base_url + '/dashboard/branchstats?fromDate=' + from + '&toDate=' + to)
  }

  getUtilizationTrend(): Observable<any> {
    return this.http.get(this.base_url + '/dashboard/utilizationtrend');
  }

  orderChangeHold(id, state): Observable<any> {
    return this.http.patch(this.base_url + "/id/" + id + "/" + state, null)
  }

  orderPickedUpByCustomer(id, remarksCustomerPickup): Observable<any> {
    return this.http.patch(this.base_url + "/id/" + id + "/customerpickup", JSON.stringify(remarksCustomerPickup));
  }

  podImageData(orderid): Observable<any> {
    return this.http.get(this.base_url + "/preSignedURLs/" + orderid);
  }

  eSignImage(orderid): Observable<any> {
    return this.http.get(this.base_url + "/preSignedURLsEsign/" + orderid);
  }

  orderInStock(orderid) {
    return this.http.patch(this.base_url + "/id/" + orderid + "/getstockstatus", null);
  }

  deleteOrders(selectedOrderList): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: selectedOrderList
    };
    return this.http.delete(this.base_url, options);
  }

  deleteAllUnplannedOrders(): Observable<any> {
    return this.http.delete(this.base_url + "/deleteallunplanned");
  }

  adminDeliverOrder(orderid) {
    return this.http.put(this.base_url + "/" + orderid + "/adminupdatedelivery/", null);
  }

  updateDeliveryStatus(orderid,actualDT) {
    return this.http.put(this.base_url + "/" + orderid + "/updatedeliverystatus?actualDeliveryDateWeb=" + actualDT, null);
  }

  adminRescheduleDelivery(order) {
    return this.http.put(this.base_url + "/stock/postponedelivery/", order);
  }

  getAllOrdersByTypesDateRange(orderTypes, page: number, from: Date, to: Date, selectedZone: string): Observable<any> {
    if ((window.sessionStorage.getItem("selectedBranchesCodes") && window.sessionStorage.getItem("totalUserBranches") == JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length) || !window.sessionStorage.getItem("selectedBranchesCodes") || JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")).length == 0) {
      return this.http.get(this.base_url + '/typesdaterange' + '?page=' + page + '&types=' + orderTypes + '&fromDate=' + from + '&toDate=' + to + '&zone=' + selectedZone);
    }
    else {
      return this.http.get(this.base_url + '/typesdaterange' + '?page=' + page + '&types=' + orderTypes + '&fromDate=' + from + '&toDate=' + to  + '&branchCodes=' + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")) + '&zone=' + selectedZone);

    }
  }
  // Arjun start 
  searchOrdersByInvoiceNumber(
    orderInvoiceNumber: string,
    page: number,
    fromDate: string,
    toDate: string,
    zone: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('fromDate', fromDate)
      .set('toDate', toDate)
      .set('zone', zone);
  
    if (window.sessionStorage.getItem('selectedBranchesCodes')) {
      params = params.set('branchCodes', JSON.parse(window.sessionStorage.getItem('selectedBranchesCodes')).join(','));
    }
  
    return this.http.get(`${this.base_url}/invoice/${orderInvoiceNumber}`, { params });
  }
  

  // arjun end 

  getJobDetails(orderid): Observable<any> {
    return this.http.get(environment.appUrl + '/jobs/id/' + orderid);
  }

  getCustomerFeedack(orderid): Observable<any> {
    return this.http.get(environment.appUrl + '/customerfeedback/id/' + orderid);
  }

  getOrderReport(from, to): Observable<any> {
    return this.http.get(environment.appUrl + '/reports/orderSummary?fromDate=' + from + '&toDate=' + to + "&branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
  }

  getOrderDetails(orderid): Observable<any> {
    return this.http.get(this.base_url + '/id/' + orderid);
  }

  updateOrderQuantity(): Observable<any> {
    return this.http.put(environment.appUrl + '/order/products/quantities', null);
  }

  updatePODAudit(jobId,podValid,remark): Observable<any> {
    return this.http.put(environment.appUrl + '/pod/job?jobId='+jobId+'&podValid='+podValid+'&remark='+remark,null);
  }

  getPodComplianceReport(from,to): Observable<any>{
    return this.http.get(environment.appUrl + '/reports/podstatsbybranch?fromDate=' + from + '&toDate=' + to  + "&branchCodes=" + JSON.parse(window.sessionStorage.getItem("selectedBranchesCodes")));
  }
}