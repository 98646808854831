import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import {
  MatPaginator, MatTableDataSource, MatDatepickerInputEvent,
  MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { TripService } from '../../../services/trip/trip.service';
import { OrderService } from '../../../services/order/order.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NotifierService } from 'angular-notifier';
import { TokenStorage } from 'src/app/auth/token.storage';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { FormControl } from '@angular/forms';




export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'it' }, //you can change useValue
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})

export class AdminComponent implements OnInit {
  columnsToDisplay = [
    'date',
    'invoice',
    'status',
    'origin',
    'receiverDetails',
    'delete'
  ];
  orderInvoiceNumber: string;
  invoiceNumberControl = new FormControl();
  orderDataArray = new MatTableDataSource;
  previousDataArray: any[] = []; 
  page: number = 0;
  totalRecords: number;
  dispatchDate: string;
  minDate: Date;
  unplannedcount: number = 0;
  onholdcount: number = 0;
  intransitcount: number = 0;
  scheduledcount: number = 0;
  failedcount: number = 0;
  nostockcount: number = 0;
  orderDataArrayForCheck = [];
  selectedOrdersDataArray: Array<any> = [];
  selectedForDelete = [];
  postSNResponse;
  postSNRes;
  fromDate: any;
  toDate: any;
  isLoading: boolean = false;
  isSearchLoading = false;
  deleteSpinner: boolean = false;
  isAllSelected: boolean;
  isPageAllSelected = [];
  isKcc: boolean = false;
  isAllScheduled: boolean = false;
  @ViewChild(MatSort) sort: MatSort;


  constructor(private orderService: OrderService,
    private router: Router,
    private dialog: MatDialog,
    private tripService: TripService,
    private datePipe: DatePipe,
    private notifierService: NotifierService,
    private token: TokenStorage,
    private dialogService: DialogService
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    if (this.token.getTenant() == 'kimberly-dist-prod' || this.token.getTenant() == 'kimberly-dist-test')
      this.isKcc = true;
    let date, last, day, month, year;
    date = new Date();
    last = new Date(date.getTime() - (10 * 24 * 60 * 60 * 1000)); // 10 * 24 * 60 * 60 * 1000 Note 10 is days
    day = last.getDate();
    month = last.getMonth();
    year = last.getFullYear();
    this.fromDate = this.datePipe.transform(new Date(year, month, day), 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    //this.postSNRes = localStorage.getItem(this.postSNResponse);
    // this.minDate = new Date();
    this.getData().subscribe(allOrders => {
      if (allOrders) {
        let isAllScheduleCount = 0;
        this.totalRecords = allOrders.totalElements;
        allOrders.content.forEach(function (obj) {
          if (obj.oState == 'ORDER_STATE_SCHEDULED')
            isAllScheduleCount = isAllScheduleCount + 1
          obj.isChecked = false
        });
        this.isLoading = false;
        this.orderDataArray.data = allOrders.content;
        this.orderDataArrayForCheck = allOrders.content;

        //disable select all option if all orders on page are scheduled
        if (isAllScheduleCount == this.orderDataArray.data.length)
          this.isAllScheduled = true;

        this.orderDataArray.sortingDataAccessor = (item: any, property) => {
          switch (property) {
            case 'receiverDetails': return item.customerInfo.customerName + " " + item.customerInfo.address.area;
            case 'status': return item.oState;
            case 'date': return this.datePipe.transform(item.deliveryETADateTime, 'mediumDate');
            case 'origin': return item.originBranch.branchName + " " + item.originBranch.branchCode;
          }
        };
        this.orderDataArray.sort = this.sort;
        for (let i = 0; i < allOrders.totalPages; i++) {
          this.isPageAllSelected.push(0);
        }
      }
    }, Error => {
      this.orderFetchError();
    });
  }


  getData() {
    if (this.isKcc){
      // return this.orderService.getAllOrdersByTypesDateRange(['ORDER_STATE_IN_PLANNING', 'ORDER_STATE_UNPLANNED', 'ORDER_STATE_SCHEDULED'], this.page, this.fromDate, this.toDate, 'nozone');
      return this.orderService.getAllOrdersByTypesDateRange(['ORDER_STATE_IN_PLANNING', 'ORDER_STATE_UNPLANNED'], this.page, this.fromDate, this.toDate, 'nozone');
    }
    else
      return this.orderService.getAllOrdersByTypesDateRange('ORDER_STATE_UNPLANNED', this.page, this.fromDate, this.toDate, 'nozone');
  }


  /*   isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
    expandedElement: any; */

  HoldOrder(order) {
    order.oState = "ORDER_STATE_ON_HOLD";
    this.orderService.orderChangeHold(order.orderUniqueId, order.oState).subscribe(res => {
      alert("order" + " " + order.orderUniqueId + " " + "is moved on hold");
    }, Error => {
      console.log(Error);
    });
  }

  deleteOrderConfirm() {
    const delOrder = this.dialog.open(DeleteOrderConfirm, {
      width: '300px',
      data: 'Are you sure you want to delete orders?'
    });

    delOrder.afterClosed().subscribe(result => {
      console.log(result)
      if (result) {
        {
          this.OnOrderDelete(this.selectedOrdersDataArray);
        }
      }
    });
  }

  OnOrderDelete(selected) {
    this.deleteSpinner = true;
    this.orderService.deleteOrders(selected).subscribe(res => {
      this.deleteSpinner = false;
      this.notifierService.notify("success","Order Deleted Successfully...")
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // this.router.navigate(["/admin"]));   
      this.router.navigate(["/manageordersandjobs"]));    
    }, Error => {
      console.log(Error);
      this.deleteSpinner = false;
      this.notifierService.notify("Error", "Error in deleting orders. Please try again later.")
    });


  }


  deleteAllUnplanneddOrdersConfirm() {
    const delOrder = this.dialog.open(DeleteOrderConfirm, {
      width: '300px',
      data: 'Are you sure you want to delete all unplanned orders?'
    });

    delOrder.afterClosed().subscribe(result => {
      console.log(result)
      if (result) {
        {
          this.OnAllUnplannedOrderDelete();
        }
      }
    });
  }

  OnAllUnplannedOrderDelete() {
    this.deleteSpinner = true;
    this.orderService.deleteAllUnplannedOrders().subscribe(res => {
      this.deleteSpinner = false;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      //  this.router.navigate(["/admin"]));
      this.router.navigate(["/manageordersandjobs"]));
    }, Error => {
      console.log(Error);
      this.deleteSpinner = false;
      this.notifierService.notify("Error", "Error in deleting orders. Please try again later.")
    });


  }

  // pageChanged(event) {
  //   this.isLoading = true;
  //   this.orderDataArray.data = [];
  //   // this.isAllSelected = true;
  //   this.page = event.pageIndex;
  //   if (this.isPageAllSelected[this.page] == true) {
  //     this.isAllSelected = true;
  //   }
  //   else
  //     this.isAllSelected = false;
  //   this.getData().subscribe(allOrders => {
  //     if (allOrders) {
  //       let isAllScheduleCount = 0;
  //       this.totalRecords = allOrders.totalElements;
  //       let selOrder = this.selectedOrdersDataArray;
  //       allOrders.content.forEach(function (obj) {
  //         if (obj.oState == 'ORDER_STATE_SCHEDULED')
  //           isAllScheduleCount = isAllScheduleCount + 1
  //         obj.isChecked = false
  //       });

  //       this.orderDataArrayForCheck = allOrders.content;
  //       /*this filters selected orders that is ticked orders so that when user
  //        clicks next or prev button the selected order won't loss*/
  //       this.orderDataArrayForCheck.filter(r => {
  //         selOrder.forEach(l => {
  //           if (l == r.orderUniqueId) {
  //             r.isChecked = true;
  //           }
  //         });
  //       });
  //       this.isLoading = false;
  //       this.orderDataArray.data = allOrders.content;
        
  //       //disable select all option if all orders on page are scheduled
  //       if (isAllScheduleCount == this.orderDataArray.data.length)
  //         this.isAllScheduled = true;
  //     }
  //   }, Error => {
  //     this.orderFetchError();
  //   });

  // }

  pageChanged(event: any): void {
    this.isLoading = true; // Start loading spinner
    this.orderDataArray.data = []; // Clear previous data
    this.page = event.pageIndex; // Update the current page
  
    // Check if all orders on the current page are selected
    this.isAllSelected = this.isPageAllSelected[this.page] === true;
  
    this.getData().subscribe(allOrders => {
      if (allOrders) {
        let isAllScheduleCount = 0;
  
        // Update total records
        this.totalRecords = allOrders.totalElements;
  
        // Preserve selected orders
        const selOrder = this.selectedOrdersDataArray;
  
        // Process the fetched orders
        allOrders.content.forEach(order => {
          if (order.oState === 'ORDER_STATE_SCHEDULED') {
            isAllScheduleCount += 1;
          }
          order.isChecked = selOrder.includes(order.orderUniqueId);
        });
  
        // Update the orderDataArrayForCheck and data source
        this.orderDataArrayForCheck = allOrders.content;
        this.orderDataArray.data = allOrders.content;
  
        // Update selection status for the select-all checkbox
        this.isAllScheduled = isAllScheduleCount === this.orderDataArray.data.length;
  
        this.isLoading = false; // Stop loading spinner
      }
    }, error => {
      console.error('Error fetching orders', error);
      this.isLoading = false; // Stop loading spinner on error
      this.orderFetchError(); // Handle error
    });
  }
  

  orderFetchError() {
    this.orderDataArray.data = [];
    this.orderDataArrayForCheck = [];
    this.isLoading = false;
    this.notifierService.notify("error", "Error In Fetching Orders. Please try after some time.")
  }

  selOrders(productchecked, Event) {
    if (Event.checked) {
      productchecked.bMarkForDelivery = true;
    } else {
      productchecked.bMarkForDelivery = false;
    }
  }

  onToggleChange() {
    this.tripService.togglePostSN().subscribe(postSN => {
      localStorage.setItem(this.postSNResponse, postSN);
    },
      Error => {
        console.log(Error);
      });
  }

  // addToDelete(order, Event) {
  //   let oid = order.orderUniqueId;
  //   let foundOrder = this.orderDataArrayForCheck.find(pl => pl.orderUniqueId === oid);
  //   if (Event.checked) {
  //     let index = this.orderDataArrayForCheck.indexOf(foundOrder);
  //     this.orderDataArrayForCheck[index].isChecked = true;
  //     this.selectedOrdersDataArray.push(order.orderUniqueId);
  //   } else if (!Event.checked) {
  //     if (this.isPageAllSelected[this.page] == true) {
  //       this.isAllSelected = false;
  //       this.isPageAllSelected[this.page] = false;
  //     }
  //     let index = this.orderDataArrayForCheck.indexOf(foundOrder);
  //     let removeIndex = this.selectedOrdersDataArray.findIndex(i => i == order.orderUniqueId);
  //     this.orderDataArrayForCheck[index].isChecked = false;
  //     this.selectedOrdersDataArray.splice(removeIndex, 1);
  //   }
  //   this.orderDataArray.data = this.orderDataArrayForCheck;
  //   this.checkAllOrdersAreAlreadySelectedFromFilter();

  // }


  // Arjun start 
  addToDelete(order, Event) {
    let oid = order.orderUniqueId;
    let foundOrder = this.orderDataArrayForCheck.find(pl => pl.orderUniqueId === oid);
    if (Event.checked) {
      let index = this.orderDataArrayForCheck.indexOf(foundOrder);
      this.orderDataArrayForCheck[index].isChecked = true;
      this.selectedOrdersDataArray.push(order.orderUniqueId);  // Add order to delete array
    } else if (!Event.checked) {
      let index = this.orderDataArrayForCheck.indexOf(foundOrder);
      let removeIndex = this.selectedOrdersDataArray.findIndex(i => i == order.orderUniqueId);
      this.orderDataArrayForCheck[index].isChecked = false;
      this.selectedOrdersDataArray.splice(removeIndex, 1);  // Remove from delete array
    }
    this.orderDataArray.data = this.orderDataArrayForCheck;
    this.checkAllOrdersAreAlreadySelectedFromFilter();
  }

  // Arjun end 
  //this function checks whether All orders from seleced filter are already selected
  checkAllOrdersAreAlreadySelectedFromFilter() {
    for (let i = 0; i < this.orderDataArrayForCheck.length; i++) {
      let findOrder = this.selectedOrdersDataArray.findIndex(sel => sel === this.orderDataArrayForCheck[i].orderUniqueId);
      if (findOrder == -1) {
        this.isAllSelected = false;
        this.isPageAllSelected[this.page] = false;
        break;
      }
      else {
        this.isAllSelected = true;
        this.isPageAllSelected[this.page] = true;
      }
    }
  }
// Arjun add 
onInvoiceNumberChange(event: string): void {
  this.orderInvoiceNumber = event.trim();

  // If the invoice number is empty, call the pageChanged method to load previous data
  if (!this.orderInvoiceNumber) {
    this.pageChanged({ pageIndex: this.page });
  }
}

searchUsersByInvoice(): void {
  if (this.orderInvoiceNumber.trim()) {
    this.isSearchLoading = true;

    this.orderService.searchOrdersByInvoiceNumber(
      this.orderInvoiceNumber,
      this.page,
      this.fromDate,
      this.toDate,
      'nozone'
    ).subscribe(result => {
      this.isSearchLoading = false;
      
      // Clear the selected orders array before new search
      this.selectedOrdersDataArray = [];

      // Load search results into the table
      this.orderDataArray.data = result.content;
      this.totalRecords = result.totalElements;

      // Check and uncheck orders as per the selection
      this.orderDataArrayForCheck = result.content;
      this.orderDataArrayForCheck.forEach(order => {
        order.isChecked = false;
      });
    }, error => {
      console.error('Error searching orders', error);
      this.isSearchLoading = false;
    });
  } else {
    // If invoice number is empty, reset data
    this.resetOrderData();
  }
}
resetOrderData(): void {
  this.orderDataArray.data = []; // Clear data
  this.totalRecords = 0; // Reset total records
  this.isSearchLoading = false; // Stop loading spinner
}
// Arjun end

  deliveryDateChange(event: MatDatepickerInputEvent<Date>, order) {
    const dialogRef = this.dialog.open(ChangeStatusDialogConfirm, {
      width: '300px',
      data: 'dateChange'
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let date = this.datePipe.transform(new Date(event.value), 'yyyy-MM-dd')
        let getDate = date + 'T05:30:00';
        delete order.isChecked;
        order.deliveryETADateTime = getDate;
        this.orderService.orderUpdate(order).subscribe(res => {
        }, Error => {
          console.log(Error);
        });
      }
    });
  }

  selectAll(event) {
    if (event.checked) {
      this.isPageAllSelected[this.page] = true;
      this.isAllSelected = true;
      this.orderDataArrayForCheck.forEach(function (obj) {
        if (obj.oState != 'ORDER_STATE_SCHEDULED')
          obj.isChecked = true;
        else
          obj.isChecked = false;
      });
      this.orderDataArray.data = this.orderDataArrayForCheck;
      this.orderDataArrayForCheck.forEach(order => {
        if (order.oState != 'ORDER_STATE_SCHEDULED') {
          //skip order if already exist
          let isOrderExistIndex = this.selectedOrdersDataArray.findIndex(i => i == order.orderUniqueId);
          if (isOrderExistIndex == -1) {
            this.selectedOrdersDataArray.push(order.orderUniqueId);
          }
        }

      });
    }
    else {
      this.isPageAllSelected[this.page] = false;
      this.isAllSelected = false;
      this.orderDataArrayForCheck.forEach(function (obj) { obj.isChecked = false; });

      //remove unselected orders from already selected orders
      this.orderDataArrayForCheck.forEach(order => {
        let removeIndex = this.selectedOrdersDataArray.findIndex(i => i == order.orderUniqueId);
        this.selectedOrdersDataArray.splice(removeIndex, 1);
      });
      this.orderDataArray.data = this.orderDataArrayForCheck;
    }
  }

  orderDetails(data) {
    this.dialogService.orderDetails(data.orderUniqueId,'orderlistview');
  }
}




@Component({
  selector: 'dialog-confirm',
  template: `
  <div style="padding:20px;">
  <div *ngIf = "data==='dateChange'; else othervalue">
  <p> Are you sure you want to change delivery date?</p>
  </div>
  <ng-template #othervalue>
    <!-- <p> Are you sure you want to move this order to <span>{{data}}</span>?</p> -->
    <p>{{data}}</p>
    </ng-template>
    <div class="row">
    <div class="col">
       <button mat-raised-button color="primary" [mat-dialog-close]="true">Yes</button>
     </div>
      <div class="col">
        <button mat-raised-button color="warn"  [mat-dialog-close]="false">No</button>
      </div>
    </div>
  </div>
  `,
})
export class ChangeStatusDialogConfirm {
  value: string;

  constructor(
    public dialogRef: MatDialogRef<ChangeStatusDialogConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.data = data;
  }

}


@Component({
  selector: 'dialog-order-confirm',
  template: `
  <div style="padding:20px;">
    <p>{{data}}</p>
    <div class="row">
    <div class="col">
       <button mat-raised-button color="primary" [mat-dialog-close]="true">Yes</button>
     </div>
      <div class="col">
        <button mat-raised-button color="warn" (click)="onNoClick()">No</button>
      </div>
    </div>
  </div>
  `,
})


export class DeleteOrderConfirm {
  constructor(
    public delOrder: MatDialogRef<DeleteOrderConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.delOrder.close();
  }
}